import { atom, selector } from "recoil";
import { getProducts } from "@helpers/api";
const localCartKey = "cart";
const cartAtom = atom({
    key: "cartAtom",
    default: [],
});
export const getCart = selector({
    key: "getCart",
    get: ({ get }) => {
        const defaultCart = get(cartAtom);
        const products = get(getProducts);
        const localCart = window.localStorage.getItem(localCartKey);
        if (!localCart) {
            return defaultCart;
        }
        try {
            const productIds = JSON.parse(localCart);
            const cart = productIds.map(productId => products.find(product => product.id === productId)).filter(Boolean);
            return cart;
        }
        catch {
            return defaultCart;
        }
    },
    set: ({ set }, newValue) => {
        set(cartAtom, newValue);
        if (Array.isArray(newValue)) {
            const productsIds = newValue.map(product => product.id);
            window.localStorage.setItem(localCartKey, JSON.stringify(productsIds));
        }
    }
});
export const getCartVisibility = atom({
    key: "getCartVisibility",
    default: "close",
});
