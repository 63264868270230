import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { getCart, getCartVisibility } from "@helpers/cart";
import "./style.css";
export const Header = () => {
    const navigate = useNavigate();
    const cart = useRecoilValue(getCart);
    const [cartVisibility, setCartVisibility] = useRecoilState(getCartVisibility);
    const toggleCartVisibility = () => {
        setCartVisibility(cartVisibility === "close" ? "open" : "close");
    };
    return (React.createElement("header", null,
        React.createElement("div", { className: "header--title", onClick: () => navigate("/") },
            React.createElement("svg", { viewBox: "0 0 100 62" },
                React.createElement("title", null, "Undiesy"),
                React.createElement("path", { fill: "none", d: "M10 10h80v20q-15 0-30 20-10 12-20 0-15-20-30-20z" })),
            React.createElement("h1", null, "Undiesy")),
        React.createElement("div", { className: "spacer" }),
        React.createElement("div", { className: "header--cart", onClick: toggleCartVisibility },
            React.createElement("div", { className: "header--cart__number" }, cart.length),
            React.createElement("svg", { viewBox: "0 0 24 24", width: "24" },
                React.createElement("g", { fillRule: "nonzero", fill: "none" },
                    React.createElement("path", { d: "M24 0v24H0V0h24ZM12.6 23.3h-.2v.5h.2v-.5Zm.3-.2-.2.1v.5l.2.1v-.6Zm-.8 0v.7h.2v-.5l-.2-.2Z" }),
                    React.createElement("path", { className: "header--cart__icon", d: "M12 2a4 4 0 0 1 4 4h2c1.1 0 2 .9 2 2l.5 12a2 2 0 0 1-2 2h-13a2 2 0 0 1-2-2L4 8c0-1.1.9-2 2-2h2a4 4 0 0 1 4-4ZM8 8H6l-.5 12h13L18 8h-2v1a1 1 0 0 1-2 .1V8h-4v1a1 1 0 0 1-2 .1V8Zm4-4c-1 0-2 .8-2 1.9V6h4a2 2 0 0 0-2-2Z" }))))));
};
