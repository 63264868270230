import React from "react";
import { useRecoilState } from "recoil";
import { getCart } from "@helpers/cart";
import { formatPrice } from "utils";
import "./style.css";
export const Product = ({ product }) => {
    const { brand, images, name, price, size } = product;
    const [cart, setCart] = useRecoilState(getCart);
    const onAddToCart = () => {
        if (cart.includes(product)) {
            return;
        }
        setCart([...cart, product]);
    };
    const isProductInCart = (product) => {
        for (let element of cart) {
            if (element.id === product.id) {
                return true;
            }
        }
        return false;
    };
    return (React.createElement("div", { className: "product" },
        React.createElement("div", { className: "product--image" },
            React.createElement("img", { src: images[0].url, alt: brand.name + " " + name })),
        React.createElement("div", { className: "product--details" },
            React.createElement("h2", null, brand.name),
            React.createElement("h3", null, name),
            React.createElement("h3", null,
                "Size : ",
                size)),
        React.createElement("button", { className: "product--add-button", onClick: onAddToCart, disabled: isProductInCart(product) },
            React.createElement("span", { className: "product--add-button__add" },
                React.createElement("svg", { viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" },
                    React.createElement("g", { fillRule: "nonzero", fill: "none" },
                        React.createElement("path", { d: "M24 0v24H0V0h24ZM12.6 23.3h-.2v.5h.2v-.5Zm.3-.2-.2.1v.5l.2.1v-.6Zm-.8 0v.7h.2v-.5l-.2-.2Z" }),
                        React.createElement("path", { className: "product--add-button__svg-color", d: "M12 2a4 4 0 0 1 4 4h2c1.1 0 2 .9 2 2l.5 12a2 2 0 0 1-2 2h-13a2 2 0 0 1-2-2L4 8c0-1.1.9-2 2-2h2a4 4 0 0 1 4-4ZM8 8H6l-.5 12h13L18 8h-2v1a1 1 0 0 1-2 .1V8h-4v1a1 1 0 0 1-2 .1V8Zm4-4c-1 0-2 .8-2 1.9V6h4a2 2 0 0 0-2-2Z" }))),
                "Add to cart"),
            React.createElement("span", { className: "product--add-button__price" }, formatPrice(price)))));
};
