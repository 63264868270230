import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { App } from "@components/App";
import "./styles.css";
import "../assets/favicon.svg";
// @ts-expect-error
const root = createRoot(document.querySelector("#app"));
root.render(React.createElement(RecoilRoot, null,
    React.createElement(BrowserRouter, null,
        React.createElement(App, null))));
