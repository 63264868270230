import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import { getCart, getCartVisibility } from "@helpers/cart";
import { formatPrice } from "utils";
import "./style.css";
export const Cart = () => {
    const [cart, setCart] = useRecoilState(getCart);
    const [cartVisibility, setCartVisibility] = useRecoilState(getCartVisibility);
    useEffect(() => {
        const className = "cart-open";
        if (cartVisibility === "open" && !document.body.classList.contains(className)) {
            document.body.classList.add(className);
            return;
        }
        if (cartVisibility === "close" && document.body.classList.contains(className)) {
            document.body.classList.remove(className);
            return;
        }
    }, [cartVisibility]);
    const checkout = async () => {
        const response = await fetch("https://api.undiesy.com/checkout", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ cart: cart.map(product => product.priceId) }),
        });
        const { data: { redirectUrl } } = await response.json();
        if (!redirectUrl) {
            throw new Error("Weird stonks");
        }
        window.location.assign(redirectUrl);
    };
    const removeFromCart = (product) => {
        setCart(cart.filter(element => product !== element));
    };
    const getSubTotal = () => {
        return cart.reduce((previous, current) => {
            return current.price + previous;
        }, 0);
    };
    const getShipping = () => {
        return 14.99;
    };
    const getTotal = () => {
        return getSubTotal() + getShipping();
    };
    const closeCart = () => {
        setCartVisibility("close");
    };
    if (cartVisibility === "close") {
        return React.createElement(AnimatePresence, null);
    }
    const backdropVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
    };
    const contentVariants = {
        hidden: { x: "100%" },
        visible: { x: "0%" },
    };
    return (React.createElement(AnimatePresence, null,
        React.createElement("div", { className: "cart" },
            React.createElement(motion.div, { className: "cart--backdrop", animate: "visible", initial: "hidden", exit: "hidden", variants: backdropVariants, transition: { ease: "easeInOut" }, onClick: closeCart }),
            React.createElement(motion.div, { className: "cart--content", animate: "visible", initial: "hidden", exit: "hidden", variants: contentVariants, transition: { ease: "easeInOut" } },
                React.createElement("div", { className: "cart--close", onClick: closeCart },
                    React.createElement("svg", { viewBox: "0 0 32 32" },
                        React.createElement("path", { d: "M7.219 5.781 5.78 7.22 14.563 16 5.78 24.781l1.44 1.439L16 17.437l8.781 8.782 1.438-1.438L17.437 16l8.782-8.781L24.78 5.78 16 14.563Z" }))),
                cart.length === 0 &&
                    React.createElement(React.Fragment, null,
                        React.createElement("h2", { className: "cart--content__title" }, "Cart"),
                        React.createElement("div", { className: "cart--content__empty" },
                            React.createElement("h2", { className: "center" }, "Your cart is empty"),
                            React.createElement("button", { onClick: closeCart }, "Continue shopping"))),
                cart.length > 0 &&
                    React.createElement(React.Fragment, null,
                        React.createElement("h2", { className: "cart--content__title" }, "Cart"),
                        React.createElement("h2", { className: "center cart--content__subtitle" },
                            "Your bag total is ",
                            formatPrice(getTotal())),
                        React.createElement("div", { className: "cart--product-list__border" }),
                        React.createElement("div", { className: "cart--product-list" },
                            React.createElement(LayoutGroup, null,
                                React.createElement(AnimatePresence, null, cart.map((product) => (React.createElement(motion.div, { key: product.id, className: "cart--product", animate: "visible", initial: "visible", exit: "hidden", variants: contentVariants, transition: { ease: "easeIn" } },
                                    React.createElement("div", null,
                                        React.createElement("img", { src: product.images[0].url, alt: product.brand + " " + product.name })),
                                    React.createElement("p", { className: "cart--product__name spacer" },
                                        React.createElement("span", { className: "cart--product__brand" }, product.brand.name),
                                        " ",
                                        product.name),
                                    React.createElement("div", null,
                                        React.createElement("p", { className: "right bold" }, formatPrice(product.price)),
                                        React.createElement("button", { className: "cart--product__remove", onClick: () => removeFromCart(product) }, "Remove")))))))),
                        React.createElement("div", { className: "spacer cart--product-list__border" }),
                        React.createElement("div", { className: "cart--content__subtotal" },
                            React.createElement("div", { className: "row" },
                                React.createElement("span", { className: "spacer" }, "Sub-total"),
                                React.createElement("span", { className: "right bold" }, formatPrice(getSubTotal()))),
                            React.createElement("div", { className: "row" },
                                React.createElement("span", { className: "spacer" }, "Shipping (Australia Post)"),
                                React.createElement("span", { className: "right bold" }, formatPrice(getShipping())))),
                        React.createElement("h2", { className: "row cart--content__total" },
                            React.createElement("span", { className: "spacer" }, "Your Total"),
                            React.createElement("span", { className: "right bold" }, formatPrice(getTotal()))),
                        React.createElement("div", { className: "column" },
                            React.createElement("button", { className: "center", onClick: () => checkout() }, "Check Out")))))));
};
