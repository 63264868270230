import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Cart } from "@components/Cart";
import { Footer } from "@components/Footer";
import { Header } from "@components/Header";
import { ProductList } from "@components/ProductList";
import "./style.css";
export const App = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, null),
        React.createElement("div", { className: "content" },
            React.createElement(Cart, null),
            React.createElement(Suspense, { fallback: React.createElement("div", null) },
                React.createElement(Routes, null,
                    React.createElement(Route, { index: true, path: "/", element: React.createElement(ProductList, null) })))),
        React.createElement(Footer, null)));
};
